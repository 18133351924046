import { pluralize, TranslationKeyConfig } from "@shared-v2/services/translatable.service";
import { StateData } from "@shared-v2/utils/state-data";
import {
  catchError,
  debounce,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  map,
  merge,
  MonoTypeOperatorFunction,
  NEVER,
  Observable,
  ObservableInput,
  of,
  OperatorFunction,
  skipWhile,
  startWith,
  switchMap,
  tap,
  throwError,
  timeout,
  TimeoutError,
  timer,
} from "rxjs";
import { LogLevel } from "../../services-v2/logger/log-level";
import Logger from "../../services-v2/logger/logger.service";
import { Translatable } from "../../types";
import { areEqualArrays } from "../arrays/utils";
import { asStateDataObservable } from "./helpers";

let debugLogger: Logger;

export function debug<T>(
  tag?: string,
  { logLevel = LogLevel.DEBUG, logger, mapper }: DebugOptions<T> = {},
): OperatorFunction<T, T> {
  logger ??= debugLogger ??= Logger.withName("ObservableDebug", { color: "green" });

  return (observable: Observable<T>) => {
    return observable.pipe(
      tap((value) => {
        const loggedValue = mapper ? mapper(value) : value;
        logger.use(logLevel, tag, loggedValue);
      }),
    );
  };
}

interface DebugOptions<T> {
  logLevel?: LogLevel;
  logger?: Logger;
  mapper?: MapperFunction<T, unknown>;
}

export function withCount<T>(): OperatorFunction<T, { value: T; count: number }> {
  let count = 0;

  return (source: Observable<T>) => {
    return source.pipe(
      tap(() => count++),
      map((value) => ({ value, count })),
    );
  };
}

export function doFirstThen<T>(
  onFirstUpdate: (value: T) => void,
  onSubsequentUpdates: (value: T) => void,
): OperatorFunction<T, T> {
  return (source: Observable<T>) => {
    return source.pipe(
      withCount(),
      tap(({ value, count }) => {
        if (count === 1) {
          onFirstUpdate(value);
          return;
        }

        onSubsequentUpdates(value);
      }),
      map(({ value }) => value),
    );
  };
}

export function startWithPending<T, E = unknown>(): OperatorFunction<
  StateData<T, E>,
  StateData<T, E>
> {
  return (source: Observable<StateData<T, E>>) => {
    return source.pipe(startWith(StateData.createPending<T, E>()));
  };
}

// Filters out StateData that is still pending.
// Will throw error if the StateData doesn't get resolved within
// the given timeout.
export function asResolvedStateData<T, E = unknown>(
  timeoutInMs = 5_000,
): OperatorFunction<StateData<T, E>, StateData<T, E>> {
  return (source: Observable<StateData<T, E>>) => {
    return source.pipe(
      filter((stateData) => !stateData.isPending()),
      timeout(timeoutInMs),
      catchErrorsAsStateData(),
    );
  };
}

export function mapToStateData<T>(): OperatorFunction<T, StateData<T>> {
  return (source: Observable<T>) => asStateDataObservable(source);
}

export function asPluralizedTranslatable<T extends number>(
  baseKeyOrConfig: string | TranslationKeyConfig,
  params?: object,
): OperatorFunction<T, Translatable> {
  return (source: Observable<T>) => {
    return source.pipe(
      map((count) => pluralize(baseKeyOrConfig, count)),
      distinctUntilChanged(),
      map((pluralizedHandle) => ({ key: pluralizedHandle, params })),
    );
  };
}

export function allowWhen<T>(
  isAllowed$: Observable<boolean>,
  { onStart, onPause, reverse = false }: AllowWhenOptions<T> = {},
): OperatorFunction<T, T> {
  return (source: Observable<T>) => {
    let lastValue: T;

    return isAllowed$.pipe(
      distinctUntilChanged(),
      switchMap((isAllowed) => {
        if (reverse) {
          isAllowed = !isAllowed;
        }

        if (!isAllowed) {
          onPause?.(lastValue);
          return NEVER;
        }

        return source.pipe(
          tap((value) => (lastValue = value)),
          withCount(),
          map(({ value, count }) => {
            if (count === 1) {
              onStart?.(value);
            }
            return value;
          }),
        );
      }),
    );
  };
}

interface AllowWhenOptions<T> {
  onStart?: (value: T) => void;
  onPause?: (value: T) => void;
  reverse?: boolean;
}

// Allows to re-update the source observable
// without adding or combining any value to it.
export function replayWhen<T>(...triggers: Observable<unknown>[]): OperatorFunction<T, T> {
  return (source: Observable<T>) => {
    return source.pipe(
      switchMap((value) => merge(...triggers).pipe(mapTo(value), startWith(value))),
    );
  };
}

export function mapTo<T, R>(value: R): OperatorFunction<T, R> {
  return (source: Observable<T>) => source.pipe(map(() => value));
}

export function mapEmptyTo<T>(defaultValue: T): OperatorFunction<T, T> {
  return (source: Observable<T>) =>
    source.pipe(
      map((value) => {
        if (value == null || value === "") {
          return defaultValue;
        }

        return value;
      }),
    );
}

export function takeFirst<T>(expectedValue: T): OperatorFunction<T, T> {
  return (source: Observable<T>) => {
    return source.pipe(
      skipWhile((value) => value !== expectedValue),
      first(),
    );
  };
}

export function safeTimeout<T>(delay: number, fallbackValue: T): OperatorFunction<T, T> {
  return (source: Observable<T>) => {
    return source.pipe(
      timeout(delay),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          return of(fallbackValue);
        }

        return throwError(() => error);
      }),
    );
  };
}

export function fallbackOnError<T>(fallbackValue: T): OperatorFunction<T, T> {
  return (source: Observable<T>) => {
    return source.pipe(catchError(() => of(fallbackValue)));
  };
}

export function skipUntilSequence<T>(sequence: T[]): OperatorFunction<T, T> {
  return (source: Observable<T>) => {
    let lastValues: T[] = [];
    let hasFoundSequence = false;

    return source.pipe(
      tap((value) => {
        if (hasFoundSequence) {
          return;
        }

        if (lastValues.length === sequence.length) {
          lastValues.shift();
        }

        lastValues.push(value);
        hasFoundSequence = areEqualArrays(lastValues, sequence, true);
      }),
      filter(() => hasFoundSequence),
      finalize(() => (lastValues = [])),
    );
  };
}

export function catchErrorsAsStateData<T, E = unknown>() {
  return (source: Observable<StateData<T, E>>) => {
    return source.pipe(
      catchError((error) => {
        return of(StateData.createWithErrors<T, E>(error));
      }),
    );
  };
}

export function flatten<T>(): OperatorFunction<ObservableInput<T>, T> {
  return (source) => {
    return source.pipe(switchMap((input) => input));
  };
}

export function asDebouncedTextChange<T extends string>(
  debounceTimeMs = 500,
): MonoTypeOperatorFunction<T> {
  return (source: Observable<T>) => {
    return source.pipe(
      distinctUntilChanged(),
      debounce((value) => (value?.length ? timer(debounceTimeMs) : timer(0))),
    );
  };
}
